<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">미납주차요금</div>
			<ul>
				<li><router-link to="unpaidinfo"   title="미납주차요금 안내" class="active">안내</router-link></li>
				<li><router-link to="lookupunpaid" title="미납주차요금 조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>미납주차요금</h2>
				<h4>안내</h4>
			</article>
			<article>
				<h3>미납주차요금이란?</h3>
				<p class="hyphen">
					주차장별 운영시간이 정해져 있는데 운영 종료 후에도 남아 있어 요금을 받지 못한 것을 말하며 주차관리원이 요금을 정산하여 차량 앞 유리에 미납요금 납부 고지서를 꽂아 둠
				</p> 
			</article>
			<article>
				<h3>미체납요금 고지절차</h3>
				<div class="overflow">
					<dl class="procedure" >
						<dt>1차고지(현장)</dt>
						<dd>
							주차장 현장에서 근무자가 주차종료 2시간 전부터 잔류차량에 대한 미납고지서 발부 (차량전면 와이퍼에 부착)
						</dd>
					</dl>
					<dl class="procedure">
						<dt>2차고지 (우편)</dt>
						<dd>
							1차고지 후 미 체납 차량에 대하여 차량등록사업소에 차적조회 의뢰 후 익월에 우편 고지
						</dd>
					</dl>
					<dl class="procedure">
						<dt>3차고지 (독촉)</dt>
						<dd>
							2차 고지 후 미 체납 차량에 대하여 독촉고지(압류예고통지-우편)
						</dd>
					</dl>
					<dl class="procedure">
						<dt>압류설정</dt>
						<dd>
							3차 고지 후 미납 차량에 대하여 차량등록사업소에 압류의로 후 차량압류 설정
						</dd>
					</dl>
					<dl class="procedure">
						<dt>압류통지</dt>
						<dd>
							압류 차량에 대한 압류통지(우편)
						</dd>
					</dl>
				</div>
			</article>
			<article class="clear">
				<h3>미체납요금 납부 및 압류해지 방법</h3>
				<p class="circle">미체납요금 납부 방법</p>
				<ul>
					<li class="hyphen">현장납부: 미납요금 고지서와 함께 주차장 주차관리원에게 납부</li>
					<li class="hyphen">지로납부: 화성시내 금융기관 또는 전국 우체국 및 농협에 납부</li>
					<li class="hyphen">계좌납부: 농협은행 000-0000-0000-00 예금주: 주차사업부</li>
				</ul>
				<p class="asterisk-orange"><span class="orange">유의사항</span> : 입금자명은 반드시 차량번호로 입금하여야 처리, 이름으로 입금 시 수납이 누락 될 수 있습니다.</p>
			</article>
			<article>
				<p class="circle">입금확인 및 미납요금 문의 : (031)377-6538</p>
			</article>
			<article>
				<p class="circle">요금 미납에 대한 조치</p>
				<p>주차장법 및 회성시 주차장조례에 근거하여 고지서발송이 되며 기간까지 납부하지 않을 시 재산압류 및 체납처분을 실시합니다.</p>
			</article>
			<article class="btn-list">
				<button class="one-btn" @click="pageList">미납주차요금 결제</button>
			</article>		
		</section>
	</main>
</template>

<script>
export default {
	methods: {
		pageList(){
			this.$router.push('/lookupunpaid')
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>