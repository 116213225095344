<template>
  <section id="APP">
      <gnb-area></gnb-area>
      <category-area v-if="$route.name !== 'main' && $route.name !== 'error'"></category-area>
      <router-view></router-view>
      <footer-area></footer-area>
      <vue-confirm-dialog></vue-confirm-dialog>
  </section>
</template>
<script>

import GnbArea from './html/layout/GnbArea.vue';
import CategoryArea from './html/layout/CategoryArea.vue';
import FooterArea from './html/layout/FooterArea.vue';

export default {
  components: {
    GnbArea,
    FooterArea,
    CategoryArea,
  },
}
</script>
<style>

</style>